import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import SimpleHeader from "./component/SimpleHeader";
import axios from "axios";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import Footer from "./component/SimpleFooter";
import SubscriptionModal from "./component/express-pay/SubscriptionModal";
const subOptions = [
  { month: 1, price: 20 },
  { month: 3, price: 50 },
  { month: 6, price: 90 },
  { month: 12, price: 150 },
];
const Subscriptoin = () => {
  const location = useLocation(); // Get the location object
  const queryParams = new URLSearchParams(location.search); // Create URLSearchParams object
  const originMonth = queryParams.get("month") || 1;
  const currency = queryParams.get("currency") || "CAD";
  const originPrice =
    subOptions.find((option) => option.month === Number(originMonth))?.price ||
    20;
  const [month, setMonth] = useState(originMonth);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [openOrderForm, setOpenOrderForm] = useState(false);
  const [openSignin, setOpenSignin] = useState(false);
  const [difinedPrice, setDifinedPrice] = useState(originPrice);
  const [couponApplied, setCouponApplied] = useState(false);
  const [couponShow, setCouponShow] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(0);

  console.log(month, difinedPrice);

  useEffect(() => {
    document.title = "Secure Payment";
  });

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    return password.length >= 6; // Example: password must be at least 6 characters
  };

  const handleProcessPayment = async () => {
    let valid = true;

    if (!validateEmail(email)) {
      setEmailError("Invalid email address");
      valid = false; // Mark as invalid
    } else {
      setEmailError(""); // Clear error if valid
    }

    if (!validatePassword(password)) {
      setPasswordError("Password must be at least 6 characters long");
      valid = false; // Mark as invalid
    } else {
      setPasswordError(""); // Clear error if valid
    }

    if (valid) {
      const response = await axios.post(
        `https://api.streamdash.co/api/auth/check-user`,
        { email }
      );
      const user = response.data;
      if (user) {
        setOpenSignin(true);
      } else {
        setOpenOrderForm(true);
      }
    }
  };

  const couponCheck = async () => {
    try {
      const response = await axios.post(
        `https://api.streamdash.co/api/subscription/coupon-check`,
        { coupon }
      );
      const couponData = response.data.couponData;
      console.log(couponData);
      setDiscount(couponData.percent_off);
      setCouponApplied(true);
    } catch (error) {
      console.log(error.response.data);
    }
  };

  return (
    <div className="bg-gray-100 h-screen">
      <SimpleHeader currency={currency} />
      <div className="flex flex-col justify-center items-center px-6  xl:px-32 py-6 gap-6 bg-gray-100 w-full">
        <div className="flex flex-col md:flex-row items-start gap-6 w-full">
          {/* Left Panel - Account Creation */}
          <div className="flex flex-col items-start p-8 gap-8 w-full lg:w-1/2 bg-white border border-gray-300">
            <div className="flex  w-full justify-between items-start gap-4">
              <div className="text-[20px]  font-semibold text-[#156BE2]">
                Create Account
              </div>
              <div className="hidden md:block text-sm font-semibold text-[#4B4B4B]">
                Have an account?{" "}
                <a
                  className="text-blue-500 font-bold"
                  href="https://streamdash.co/login"
                >
                  login
                </a>
              </div>
            </div>
            <div className="flex flex-col gap-4 w-full">
              <input
                type="email"
                placeholder="Email"
                className={`rounded-lg w-full p-5 text-sm font-medium text-gray-600 border ${emailError ? "border-red-600" : "border-gray-600"
                  } focus:border-gray-600`}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && (
                <span className="text-red-600 text-sm">{emailError}</span>
              )}

              <input
                type="password"
                placeholder="Password"
                className={`rounded-lg w-full p-5 text-sm font-medium text-gray-600 border ${passwordError ? "border-red-600" : "border-gray-600"
                  } focus:border-gray-600`}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {passwordError && (
                <span className="text-red-600 text-sm">{passwordError}</span>
              )}
            </div>
            <div className="block md:hidden text-sm font-semibold text-[#4B4B4B]">
              Have an account?{" "}
              <a
                className="text-blue-500 font-bold"
                href="https://streamdash.co/login"
              >
                login
              </a>
            </div>
            {month !== 1 && (
              <div className="flex flex-row items-center text-sm lg:text-base  p-2 md:p-4 gap-1 md:gap-2 w-full h-[56px] bg-[#CFF3F2] border border-[#CFF3F2] rounded-lg">
                <span className="text-center font-medium text-gray-600">
                  You are saving $
                  {(20 * month - difinedPrice * (1 - discount / 100)).toFixed(
                    2
                  )}{" "}
                  with this order
                </span>
              </div>
            )}
            <div className="flex flex-col md:flex-row gap-4 justify-center w-full">
              {/* Desktop View */}
              <div className="hidden md:flex items-center justify-center gap-4">
                <div className="flex items-center justify-center gap-2">
                  <img
                    src="/image/pay/image1.png"
                    alt="Get Instant Access"
                    className="w-8 h-8"
                  />
                  <span className="text-gray-700 text-xs">
                    Get Instant Access
                  </span>
                </div>
                <div className="flex items-center justify-center gap-2">
                  <img
                    src="/image/pay/image2.png"
                    alt="Money Back Guarantee"
                    className="w-8 h-8"
                  />
                  <span className="text-gray-700 text-xs">
                    100% Money Back Guarantee
                  </span>
                </div>
                <div className="flex items-center justify-center gap-2">
                  <img
                    src="/image/pay/image3.png"
                    alt="Super Fast Streaming"
                    className="w-8 h-8"
                  />
                  <span className="text-gray-700 text-xs">
                    Super Fast Streaming
                  </span>
                </div>
                <div className="flex items-center justify-center gap-2">
                  <img
                    src="/image/pay/image4.png"
                    alt="Rated The Best"
                    className="w-8 h-8"
                  />
                  <span className="text-gray-700 text-xs">Rated The Best</span>
                </div>
              </div>

              {/* Mobile Carousel View */}
              <div className="md:hidden">
                <Swiper
                  spaceBetween={18}
                  slidesPerView={2}
                  pagination={{ clickable: true }} // Enable pagination without importing Pagination module
                >
                  <SwiperSlide>
                    <div className="flex items-center justify-center gap-2">
                      <img
                        src="/image/pay/image1.png"
                        alt="Get Instant Access"
                        className="w-8 h-8"
                      />
                      <span className="text-gray-700 text-xs">
                        Get Instant Access
                      </span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex items-center justify-center gap-2">
                      <img
                        src="/image/pay/image2.png"
                        alt="Money Back Guarantee"
                        className="w-8 h-8"
                      />
                      <span className="text-gray-700 text-xs">
                        100% Money Back Guarantee
                      </span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex items-center justify-center gap-2">
                      <img
                        src="/image/pay/image3.png"
                        alt="Super Fast Streaming"
                        className="w-8 h-8"
                      />
                      <span className="text-gray-700 text-xs">
                        Super Fast Streaming
                      </span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex items-center justify-center gap-2">
                      <img
                        src="/image/pay/image4.png"
                        alt="Rated The Best"
                        className="w-8 h-8"
                      />
                      <span className="text-gray-700 text-xs">
                        Rated The Best
                      </span>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>

          {/* Right Panel - Premium Access Credits */}
          <div className="flex flex-col items-start gap-4 w-full lg:w-1/2">
            <div className="flex flex-col items-start p-8 gap-4 w-full  bg-white border border-gray-300">
              <div className="text-[20px] font-semibold text-[#156BE2]">
                Premium Access Subscription
              </div>
              <div className="flex flex-col lg:flex-row justify-between items-center w-full ">
                <div className="flex flex-col items-start gap-2 w-full lg:w-3/4">
                  <select
                    value={difinedPrice}
                    onChange={(e) => {
                      setDifinedPrice(e.target.value);
                      setMonth(
                        subOptions.find(
                          (option) => option.price === Number(e.target.value)
                        )?.month
                      );
                    }}
                    className="w-full h-[60px] p-4 bg-white border border-gray-300 rounded-lg text-lg font-medium text-gray-600"
                  >
                    {subOptions.map((option, index) => (
                      <option key={index} value={option.price}>
                        {option.month} {option.month === 1 ? "month" : "months"}
                      </option>
                    ))}
                  </select>
                  <span className="text-lg font-semibold text-gray-600">
                    Get {month} months of access
                  </span>
                </div>

                <div className="flex justify-between lg:flex-col  items-start gap-2">
                  {month !== 1 && (
                    <div className="flex items-center mx-auto text-lg p-2 bg-[#F67878] rounded-full">
                      <span className="text-white font-bold text-sm">
                        Save ${" "}
                        {(
                          20 * month -
                          difinedPrice * (1 - discount / 100)
                        ).toFixed(2)}
                      </span>
                    </div>
                  )}
                  <div className="flex flex-col items-start text-center text-sm lg:text-base mx-auto">
                    <span className="text-sm md:text-xl font-semibold text-gray-600">
                      ${(difinedPrice / month).toFixed(2)} / month
                    </span>
                    {month !== 1 && (
                      <span className="text-sm text-gray-400 line-through">
                        $ 20 / month
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Subtotal & Pay Now */}
            <div className="flex flex-col items-center p-8 gap-4 w-full h-[252px] bg-white border border-gray-300">
              <div className="flex justify-between items-center w-full">
                <span className="text-xl font-semibold text-[#156BE2]">
                  Subtotal
                </span>
                <span className="text-lg font-semibold text-gray-800">
                  ${(difinedPrice * (1 - discount / 100)).toFixed(2)}
                </span>
              </div>

              {!couponShow ? (
                <div
                  className="text-xl mr-auto font-semibold cursor-pointer text-[#156BE2]"
                  onClick={() => setCouponShow(true)}
                >
                  Have a coupon code?
                </div>
              ) : !couponApplied ? (
                <div className="flex flex-wrap md:flex-nowrap border border-gray-300 rounded-lg p-2 w-full mx-auto gap-2">
                  <input
                    type="text"
                    placeholder="Enter coupon code"
                    value={coupon}
                    onChange={(e) => setCoupon(e.target.value)}
                    className="flex-grow px-4 py-2 focus:outline-none text-gray-700 border border-gray-300 rounded-md md:rounded-none md:rounded-l-lg"
                  />
                  <button
                    onClick={couponCheck}
                    className="w-full md:w-auto text-orange-500 font-semibold px-4 py-2 focus:outline-none border border-gray-300 rounded-md md:rounded-none md:rounded-r-lg"
                  >
                    Apply
                  </button>
                </div>
              ) : (
                <div className="flex justify-between items-center w-full font-semibold">
                  <span className="text-blue-500">Coupon Applied</span>
                  <button
                    className="text-red-500 p-2 px-4 rounded-lg"
                    onClick={() => {
                      setCouponShow(false);
                      setCouponApplied(false);
                      setDiscount(null);
                      setCoupon(null);
                    }}
                  >
                    Remove Coupon
                  </button>
                </div>
              )}

              <button
                className="w-full h-[60px] bg-[#156BE2] rounded-lg text-white font-bold text-xl"
                onClick={handleProcessPayment}
              >
                Subscribe Now
              </button>
              {openSignin && (
                <div className="text-red-500 w-full text-center text-xl">
                  User already exists, please{" "}
                  <a
                    className="text-blue-500 font-bold"
                    href="https://streamdash.co/login"
                  >
                    login
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Footer Icons */}
      </div>
      {openOrderForm && (
        <SubscriptionModal
          isOpen={openOrderForm}
          onClose={() => setOpenOrderForm(false)}
          coupon={couponApplied ? coupon : null}
          month={month}
          email={email}
          currency={currency}
          password={password}
        />
      )}
      <Footer />
    </div>
  );
};

export default Subscriptoin;
