// Header.js
import React, { useState } from "react";

const Header = ({ currency }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="flex justify-between items-center py-4 px-8 md:px-24 bg-white relative">
      <div className="flex items-center">
        <img
          src="/logo.png"
          alt="istreamdash logo"
          className="h-8 w-auto mr-2 md:mr-8"
        />
      </div>
      <div className=" flex text-lg font-bold text-blue-400">
        <img src={`image/${currency}.png`} width={30} className="mr-4" />
        {currency}
      </div>
    </header>
  );
};

export default Header;
