// export const stripePriIDs = [{
//     USD: "price_1QOB1rLcwMObMFXzfoZm26WE",
//     CAD: "price_1QOB2iLcwMObMFXzUK6Qb70r",
//     month: 1
// }, {
//     USD: "price_1QOB1rLcwMObMFXzfoZm26WE",
//     CAD: "price_1QOB2iLcwMObMFXzUK6Qb70r",
//     month: 3
// }, {
//     USD: "price_1QOB1rLcwMObMFXzfoZm26WE",
//     CAD: "price_1QOB2iLcwMObMFXzUK6Qb70r",
//     month: 6
// }, {
//     USD: "price_1QOB1rLcwMObMFXzfoZm26WE",
//     CAD: "price_1QOB2iLcwMObMFXzUK6Qb70r",
//     month: 12
// }
// ];
export const stripePriIDs = [{
    USD: "price_1QNO2NLcwMObMFXzovmeCXUd",
    CAD: "price_1QNO4VLcwMObMFXzw0CVSXKw",
    month: 1
}, {
    USD: "price_1QNO7nLcwMObMFXzFVrRHraR",
    CAD: "price_1QNO8qLcwMObMFXzaIjSGbwq",
    month: 3
}, {
    USD: "price_1QNO9wLcwMObMFXzrIyy2zja",
    CAD: "price_1QNOAaLcwMObMFXzf7i0q7qs",
    month: 6
}, {
    USD: "price_1QNOBTLcwMObMFXz9yag2oeh",
    CAD: "price_1QNOCILcwMObMFXzi0SAborD",
    month: 12
}
];


// export const paypalOriginPlanIDs = [
//     {
//         USD: "P-6UT47351FR579633WM47543Y",
//         CAD: "P-8R657837EL448933EM5AVY3Y",
//         month: 1
//     },
//     {
//         USD: "P-6UT47351FR579633WM47543Y",
//         CAD: "P-8R657837EL448933EM5AVY3Y",
//         month: 3
//     },
//     {
//         USD: "P-6UT47351FR579633WM47543Y",
//         CAD: "P-8R657837EL448933EM5AVY3Y",
//         month: 6
//     },
//     {
//         USD: "P-6UT47351FR579633WM47543Y",
//         CAD: "P-8R657837EL448933EM5AVY3Y",
//         month: 12
//     }
// ]

// export const paypalDiscountPlanIDs = [
//     {
//         USD: "P-6UT47351FR579633WM47543Y",
//         CAD: "P-8R657837EL448933EM5AVY3Y",
//         month: 1
//     },
//     {
//         USD: "P-6UT47351FR579633WM47543Y",
//         CAD: "P-8R657837EL448933EM5AVY3Y",
//         month: 3
//     },
//     {
//         USD: "P-6UT47351FR579633WM47543Y",
//         CAD: "P-8R657837EL448933EM5AVY3Y",
//         month: 6
//     },
//     {
//         USD: "P-6UT47351FR579633WM47543Y",
//         CAD: "P-8R657837EL448933EM5AVY3Y",
//         month: 12
//     }

// ]
export const paypalOriginPlanIDs = [
    {
        USD: "P-9C465932L4282700TM47HBQQ",
        CAD: "P-7E540704YA318570FM47RUTI",
        month: 1
    },
    {
        USD: "P-7WM22634LF0017255M47HE6Q",
        CAD: "P-6EE562078T049664BM47RWCY",
        month: 3
    },
    {
        USD: "P-8JC230459P6191731M47HDXY",
        CAD: "P-8U995772TB8154310M47RWUI",
        month: 6
    },
    {
        USD: "P-0XK21791YA0200252M47HFWY",
        CAD: "P-4TU0957199655733HM47RXEY",
        month: 12
    }
]

export const paypalDiscountPlanIDs = [
    {
        USD: "P-5RV94389U85731258M47HJGI",
        CAD: "P-7ML99599LS409950AM47RXWQ",
        month: 1
    },
    {
        USD: "P-2X4635680P087863SM47HNQQ",
        CAD: "P-3W351229LT569901GM47RZNY",
        month: 3
    },
    {
        USD: "P-2TN99430PM746171XM47HOIQ",
        CAD: "P-2E963767G08772831M47RZ6Q",
        month: 6
    },
    {
        USD: "P-1MT49265Y5282214DM47HO5A",
        CAD: "P-8J377458KH743152DM5AUETQ",
        month: 12
    }

]