import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import SimpleHeader from "./component/SimpleHeader";
import axios from "axios";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import Footer from "./component/SimpleFooter";
import PaymentModal from "./component/express-pay/PaymentModal";
const ExpressPay = () => {
  const location = useLocation(); // Get the location object
  const queryParams = new URLSearchParams(location.search); // Create URLSearchParams object
  const credit = queryParams.get("credit") || "0"; // Get credit param
  const currency = queryParams.get("currency") || "usd"; // Get currency param

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [difinedPrice, setDifinedPrice] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [products, setProducts] = useState([]);
  const [creditOptions, setCreditOptions] = useState([]);
  const [monthlyPrice, setMonthlyPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [couponApplied, setCouponApplied] = useState(false);
  const [couponShow, setCouponShow] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [openOrderForm, setOpenOrderForm] = useState(false);
  const [openSignin, setOpenSignin] = useState(false);
  const [defineCredit, setDefineCredit] = useState(credit);
  console.log(defineCredit);

  useEffect(() => {
    document.title = "Secure Payment";
    const fetchProducts = async () => {
      const response = await fetch(`https://api.streamdash.co/api/product`);
      const data = await response.json();
      setProducts(data);
      const temp = [];
      await data.forEach((product) => {
        let object;
        if (product.credit !== "all") {
          object = {
            value:
              currency === "usd"
                ? Number(product.priceUSD) || 0
                : Number(product.priceCAD) || 0,
            label:
              product.credit === "0"
                ? "Free"
                : Number(product.credit) > 1
                ? `${product.credit} Credits`
                : `${product.credit} Credit`,
            credit: product.credit,
            couponCode: product.couponCode,
            discount: product.discount,
            couponActive: product.couponActive,
          };
          temp.push(object);
        } else {
          return;
        }
      });
      console.log(temp);
      setDifinedPrice(temp.find((option) => option.credit === credit)?.value);
      setCreditOptions(temp);
    };
    fetchProducts();
  }, [credit]);

  useEffect(() => {
    if (Number(difinedPrice) === 0) {
      setMonthlyPrice(0);
    } else {
      setMonthlyPrice(
        (
          difinedPrice /
          (creditOptions.find(
            (option) => Number(option.value) === Number(difinedPrice)
          )?.credit || 1)
        ).toFixed(2)
      );
    }
  }, [difinedPrice]);

  const applyCoupon = () => {
    if (
      creditOptions.find(
        (option) => Number(option.value) === Number(difinedPrice)
      )?.couponCode === coupon
    ) {
      setDiscount(
        (Number(difinedPrice) *
          (100 -
            Number(
              creditOptions.find(
                (option) => Number(option.value) === Number(difinedPrice)
              )?.discount
            ))) /
          100
      );
      setCouponApplied(true);
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    return password.length >= 6; // Example: password must be at least 6 characters
  };

  const handleProcessPayment = async () => {
    let valid = true;

    if (!validateEmail(email)) {
      setEmailError("Invalid email address");
      valid = false; // Mark as invalid
    } else {
      setEmailError(""); // Clear error if valid
    }

    if (!validatePassword(password)) {
      setPasswordError("Password must be at least 6 characters long");
      valid = false; // Mark as invalid
    } else {
      setPasswordError(""); // Clear error if valid
    }

    if (valid) {
      const response = await axios.post(
        `https://api.streamdash.co/api/auth/check-user`,
        { email }
      );
      const user = response.data;
      if (user) {
        setOpenSignin(true);
      } else {
        setOpenOrderForm(true);
      }
    }
  };

  return (
    <div className="bg-gray-100 h-screen">
      <SimpleHeader currency={currency} />
      <div className="flex flex-col justify-center items-center px-4 md:px-32 py-6 gap-6 bg-gray-100 w-full">
        <div className="flex flex-col md:flex-row items-start gap-12 w-full">
          {/* Left Panel - Account Creation */}
          <div className="flex flex-col items-start p-8 gap-8 w-full md:w-1/2 bg-white border border-gray-300">
            <div className="flex  w-full justify-between items-start gap-4">
              <div className="text-[20px] font-semibold text-[#156BE2]">
                Create Account
              </div>
              <div className="hidden md:block text-sm font-semibold text-[#4B4B4B]">
                Have an account?{" "}
                <a
                  className="text-blue-500 font-bold"
                  href="https://streamdash.co/login"
                >
                  login
                </a>
              </div>
            </div>
            <div className="flex flex-col gap-4 w-full">
              <input
                type="email"
                placeholder="Email"
                className={`rounded-lg  w-full p-5 text-sm font-medium text-gray-600 border ${
                  emailError ? "border-red-600" : "border-gray-600"
                } focus:border-gray-600`}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && (
                <span className="text-red-600 text-sm">{emailError}</span>
              )}

              <input
                type="password"
                placeholder="Password"
                className={`rounded-lg  w-full p-5 text-sm font-medium text-gray-600 border ${
                  passwordError ? "border-red-600" : "border-gray-600"
                } focus:border-gray-600`}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {passwordError && (
                <span className="text-red-600 text-sm">{passwordError}</span>
              )}
            </div>
            <div className="block md:hidden text-sm font-semibold text-[#4B4B4B]">
              Have an account?{" "}
              <a
                className="text-blue-500 font-bold"
                href="https://streamdash.co/login"
              >
                login
              </a>
            </div>
            {Number(defineCredit) > 1 && (
              <div className="flex flex-row items-center p-4 gap-2 w-full h-[56px] bg-[#CFF3F2] border border-[#CFF3F2] rounded-lg">
                <span className="text-center font-medium text-gray-600">
                  You are saving $
                  {(creditOptions[1]?.value - monthlyPrice) *
                    Number(defineCredit) +
                  couponApplied
                    ? difinedPrice - discount
                    : 0}{" "}
                  with this order
                </span>
              </div>
            )}
            <div className="flex flex-col md:flex-row gap-4 justify-center w-full">
              {/* Desktop View */}
              <div className="hidden md:flex items-center justify-center gap-4">
                <div className="flex items-center justify-center gap-2">
                  <img
                    src="/image/pay/image1.png"
                    alt="Get Instant Access"
                    className="w-8 h-8"
                  />
                  <span className="text-gray-700 text-xs">
                    Get Instant Access
                  </span>
                </div>
                <div className="flex items-center justify-center gap-2">
                  <img
                    src="/image/pay/image2.png"
                    alt="Money Back Guarantee"
                    className="w-8 h-8"
                  />
                  <span className="text-gray-700 text-xs">
                    100% Money Back Guarantee
                  </span>
                </div>
                <div className="flex items-center justify-center gap-2">
                  <img
                    src="/image/pay/image3.png"
                    alt="Super Fast Streaming"
                    className="w-8 h-8"
                  />
                  <span className="text-gray-700 text-xs">
                    Super Fast Streaming
                  </span>
                </div>
                <div className="flex items-center justify-center gap-2">
                  <img
                    src="/image/pay/image4.png"
                    alt="Rated The Best"
                    className="w-8 h-8"
                  />
                  <span className="text-gray-700 text-xs">Rated The Best</span>
                </div>
              </div>

              {/* Mobile Carousel View */}
              <div className="md:hidden">
                <Swiper
                  spaceBetween={18}
                  slidesPerView={2}
                  pagination={{ clickable: true }} // Enable pagination without importing Pagination module
                >
                  <SwiperSlide>
                    <div className="flex items-center justify-center gap-2">
                      <img
                        src="/image/pay/image1.png"
                        alt="Get Instant Access"
                        className="w-8 h-8"
                      />
                      <span className="text-gray-700 text-xs">
                        Get Instant Access
                      </span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex items-center justify-center gap-2">
                      <img
                        src="/image/pay/image2.png"
                        alt="Money Back Guarantee"
                        className="w-8 h-8"
                      />
                      <span className="text-gray-700 text-xs">
                        100% Money Back Guarantee
                      </span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex items-center justify-center gap-2">
                      <img
                        src="/image/pay/image3.png"
                        alt="Super Fast Streaming"
                        className="w-8 h-8"
                      />
                      <span className="text-gray-700 text-xs">
                        Super Fast Streaming
                      </span>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex items-center justify-center gap-2">
                      <img
                        src="/image/pay/image4.png"
                        alt="Rated The Best"
                        className="w-8 h-8"
                      />
                      <span className="text-gray-700 text-xs">
                        Rated The Best
                      </span>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>

          {/* Right Panel - Premium Access Credits */}
          <div className="flex flex-col items-start gap-4 w-full md:w-1/2">
            <div className="flex flex-col items-start p-8 gap-4 w-full h-[204px] bg-white border border-gray-300">
              <div className="text-[20px] font-semibold text-[#156BE2]">
                Premium Access Credits
              </div>
              <div className="flex justify-between items-center w-full h-[110px]">
                <div className="flex flex-col items-start gap-2  w-full lg:w-3/4">
                  <select
                    value={difinedPrice}
                    onChange={(e) => {
                      const selectedOption =
                        e.target.options[e.target.selectedIndex]; // Access the selected option
                      const credit = selectedOption.getAttribute("data-credit"); // Get the value of the data-credit attribute

                      setDifinedPrice(e.target.value);
                      setCouponShow(false);
                      setDiscount(null);
                      setDefineCredit(credit); // Set the defineCredit state
                    }}
                    className="w-full h-[60px] p-4 bg-white border border-gray-300 rounded-lg text-lg font-medium text-gray-600"
                  >
                    {creditOptions.map((option, index) => (
                      <option
                        key={option.credit}
                        data-credit={option.credit}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <span className="text-lg font-semibold text-gray-600">
                    Get 6 months of access
                  </span>
                </div>

                <div className="flex justify-between lg:flex-col  items-start gap-2">
                  {Number(defineCredit) > 1 && (
                    <div className="flex items-center mx-auto text-lg p-2 bg-[#F67878] rounded-full">
                      <span className="text-white font-bold text-sm">
                        Save ${" "}
                        {(creditOptions[1]?.value - monthlyPrice) *
                          Number(defineCredit) +
                        couponApplied
                          ? difinedPrice - discount
                          : 0}
                      </span>
                    </div>
                  )}
                  <div className="flex flex-col items-start">
                    <span className="text-xl font-semibold text-gray-600">
                      ${monthlyPrice} / month
                    </span>
                    <span className="text-sm text-gray-400 line-through">
                      ${creditOptions[1]?.value} / month
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Subtotal & Pay Now */}
            <div className="flex flex-col items-start p-8 gap-4 w-full h-[252px] bg-white border border-gray-300">
              <div className="flex justify-between items-center w-full">
                <span className="text-xl font-semibold text-[#156BE2]">
                  Subtotal
                </span>
                <span className="text-lg font-semibold text-gray-800">
                  {discount || difinedPrice}{" "}
                  {currency === "usd" ? "USD" : "CAD"}
                </span>
              </div>
              {Number(difinedPrice) !== 0 && (
                <>
                  {!couponShow &&
                  creditOptions.find(
                    (option) => Number(option.value) === Number(difinedPrice)
                  )?.couponActive ? (
                    <div
                      className="text-xl font-semibold cursor-pointer text-[#156BE2]"
                      onClick={() => setCouponShow(true)}
                    >
                      Have a coupon code?
                    </div>
                  ) : !couponApplied ? (
                    <div className="flex border border-gray-300 rounded-lg p-2 w-full mx-auto">
                      <input
                        type="text"
                        placeholder="Enter coupon code"
                        value={coupon}
                        onChange={(e) => setCoupon(e.target.value)}
                        className="flex-grow px-4 py-2 focus:outline-none text-gray-700"
                      />
                      <button
                        onClick={applyCoupon}
                        className="text-orange-500 font-semibold px-4 py-2 focus:outline-none"
                      >
                        Apply
                      </button>
                    </div>
                  ) : (
                    <div className="flex justify-between items-center w-full font-semibold">
                      <span className="text-blue-500">Coupon Applied</span>
                      <button
                        className="text-red-500 p-2 px-4 rounded-lg"
                        onClick={() => {
                          setCouponShow(false);
                          setCouponApplied(false);
                          setDiscount(null);
                        }}
                      >
                        Remove Coupon
                      </button>
                    </div>
                  )}
                </>
              )}
              <button
                className="w-full h-[60px] bg-[#156BE2] rounded-lg text-white font-bold text-xl"
                onClick={handleProcessPayment}
              >
                Pay Now
              </button>
              {openSignin && (
                <div className="text-red-500 w-full text-center text-xl">
                  User already exists, please{" "}
                  <a
                    className="text-blue-500 font-bold"
                    href="https://streamdash.co/login"
                  >
                    login
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Footer Icons */}
      </div>
      {openOrderForm && (
        <PaymentModal
          isOpen={openOrderForm}
          onClose={() => setOpenOrderForm(false)}
          price={discount || difinedPrice}
          currency={currency}
          email={email}
          credit={
            creditOptions.find(
              (option) => Number(option.value) === Number(difinedPrice)
            )?.credit || credit
          }
          password={password}
        />
      )}
      <Footer />
    </div>
  );
};

export default ExpressPay;
