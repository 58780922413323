import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./component/SubscriptionCheckout";
import PayPalButton from "./component/SubscriptionPaypalButton";
import { stripePriIDs, paypalOriginPlanIDs, paypalDiscountPlanIDs } from "./component/data";
import "./App.css";
import axios from "axios";
const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const baseUrl = process.env.REACT_APP_BASE_URL;
const stripePromise = loadStripe(stripeKey);

function ChangeSubscriptoin() {
  const location = useLocation();
  // Extract the query parameters from the URL
  const queryParams = new URLSearchParams(location.search);
  const month = queryParams.get("month") || 1;
  const email = queryParams.get("email") || 1;
  const currency = queryParams.get("currency") || "USD";
  const price = queryParams.get("price") || 20;
  const previousSubscriptionId = queryParams.get("previousSubscriptionId") || null;
  const subscriptoinType = queryParams.get("subscriptoinType") || "monthly";
  const stripePriceId = stripePriIDs.find(option => option.month === Number(month))[currency];
  const paypalPriceId = paypalOriginPlanIDs.find(option => option.month === Number(month))[currency];
  const paypalDiscountPriceId = paypalDiscountPlanIDs.find(option => option.month === Number(month))[currency];
  const [showSuccessCredit, setShowSuccessCredit] = useState(false);
  const [couponApplied, setCouponApplied] = useState(false);
  const [couponShow, setCouponShow] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('credit');




  const cancelPreviousSubscription = () => {
    if (previousSubscriptionId) {
      axios.post(`https://api.streamdash.co/api/subscription/${subscriptoinType}-cancel-subscription`, { subscriptionId: previousSubscriptionId });
    }
  };


  const handleAddCredit = (subscriptionType, subscriptionId) => {
    axios
      .post(`https://api.streamdash.co/api/subscription/change-subscription`, { email, month, subscriptionType, subscriptionId })
      .then(() => {
        setShowSuccessCredit(true);
        cancelPreviousSubscription();
      })
      .catch(() => {
        console.log("network error");
      });
  };

  const handleCouponApply = async () => {
    try {
      const response = await axios.post(`https://api.streamdash.co/api/subscription/coupon-check`, { coupon: coupon });
      const couponData = response.data.couponData;
      setDiscountedPrice(price * (1 - couponData.percent_off / 100).toFixed(2));
      setCouponApplied(true);
    } catch (error) {
      console.error("Error applying coupon:", error);
    }
  };

  const handleCloseWindow = () => {
    window.close();
  };
  return (
    <div className="payment-container">
      <div className="payment-wrapper">
        {showSuccessCredit ? (
          <>
            <button className="back-link" onClick={handleCloseWindow}>
              ← Close
            </button>
            <h2 className="success-message">
              Subscription changed successfully. You purchased {month} month
              {month > 1 ? "s" : ""}.
            </h2>
          </>
        ) : (
          <>

            <button className="back-link mb-6" onClick={handleCloseWindow}>
              ← Close
            </button>

            <div className="package-details">
              <div className="package-info">
                <p className="value">{month} Month{month > 1 ? "s" : ""}</p>
              </div>
              <div className="payment-info">
                {discountedPrice ? (
                  <p className="value price-line">
                    <span className="original-price" style={{ colors: "red" }}>
                      <s>{price}</s>
                    </span>{" "}
                    <span className="discounted-price">{discountedPrice}</span>{" "}
                    {currency.toUpperCase()}
                  </p>
                ) : (
                  <p className="value">
                    {price} {currency.toUpperCase()}
                  </p>
                )}
              </div>
            </div>
            {!couponShow ? (
              <div
                className="text- mb-4 mr-auto font-semibold cursor-pointer text-[#156BE2]"
                onClick={() => setCouponShow(true)}
              >
                Have a coupon code?
              </div>
            ) : !couponApplied ? (
              <div className="flex border border-gray-300 rounded-lg p-2 w-full mx-auto">
                <input
                  type="text"
                  placeholder="Enter coupon code"
                  value={coupon}
                  onChange={(e) => setCoupon(e.target.value)}
                  className="flex-grow px-4 py-2 focus:outline-none text-gray-700"
                />
                <button
                  onClick={handleCouponApply}
                  className="text-orange-500 font-semibold px-4 py-2 focus:outline-none"
                >
                  Apply
                </button>
              </div>
            ) : (
              <div className="flex justify-between items-center w-full font-semibold">
                <span className="text-blue-500">Coupon Applied</span>
                <button
                  className="text-red-500 p-2 px-4 rounded-lg"
                  onClick={() => {
                    setCouponShow(false);
                    setCouponApplied(false);
                    setDiscount(null);
                    setCoupon(null);
                  }}
                >
                  Remove Coupon
                </button>
              </div>
            )}

            <div className="my-4">

              <label className="flex items-center space-x-2 mb-2">
                <input
                  type="radio"
                  name="payment-method"
                  checked={paymentMethod === 'credit'}
                  onChange={() => setPaymentMethod('credit')}
                  className="form-radio text-blue-600"
                />
                <div className="flex w-full justify-between items-center gap-2">
                  <span className="text-2xl font-semibold">Credit card</span>
                  <img src="/image/card.png" alt="credit" className="h-8" />
                </div>
              </label>
              {paymentMethod === 'credit' && (
                <Elements stripe={stripePromise}>
                  <CheckoutForm
                    priceId={stripePriceId}
                    month={month}
                    callBack={handleAddCredit}
                    email={email}
                    coupon={coupon}
                  />
                </Elements>
              )}
            </div>
            <div className="mb-6">
              <label className="flex items-center space-x-2 mb-2">
                <input
                  type="radio"
                  name="payment-method"
                  checked={paymentMethod === 'paypal'}
                  onChange={() => setPaymentMethod('paypal')}
                  className="form-radio text-blue-600"
                />
                <div className="flex w-full justify-between items-center gap-2">
                  <span className="text-2xl font-semibold">PayPal</span>
                  <img src="/image/paypal.png" alt="paypal" className="h-8" />
                </div>
              </label>
              {paymentMethod === 'paypal' && (
                <PayPalButton callback={handleAddCredit}
                  planId={couponApplied ? paypalDiscountPriceId : paypalPriceId} />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ChangeSubscriptoin;
