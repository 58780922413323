import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

function PayPalButtonComponent({ callback, planId }) {
    const [{ isPending }] = usePayPalScriptReducer();



    const buttonStyles = {
        layout: "vertical",
        color: "gold",
        shape: "rect",
        label: "paypal",
    };

    const successOrder = (subscriptionId) => {
        callback("paypal", subscriptionId);
    };

    return (
        <>
            {isPending ? (
                <p>Loading PayPal Button...</p> // Loader while the script is loading
            ) : (
                <PayPalButtons
                    style={buttonStyles}
                    createSubscription={(data, actions) => {
                        return actions.subscription.create({
                            plan_id: planId,
                        });
                    }}

                    onApprove={(data) => {
                        successOrder(data.subscriptionID);
                    }}

                    onError={(err) => {
                        console.error("PayPal Buttons error:", err);
                    }}
                />
            )}
        </>
    );
}

export default function PaypalButton({ callback, planId }) {
    const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;
    const successOrder = (type, subscriptionId) => {
        callback(type, subscriptionId);

    };

    const initialOptions = {
        clientId: paypalClientId,
        components: "buttons",
        "disable-funding": "paylater,card",
        vault: true, // Enable vault for subscriptions
        intent: "subscription",
    };

    return (
        <PayPalScriptProvider options={initialOptions}>
            <PayPalButtonComponent callback={successOrder} planId={planId} />
        </PayPalScriptProvider>
    );
}
