import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

function PayPalButtonComponent({ callback, price }) {
    const [{ isPending }] = usePayPalScriptReducer();

    const buttonStyles = {
        layout: "vertical",
        color: "gold",
        shape: "rect",
        label: "paypal",
    };

    const successOrder = () => {
        callback();
    };

    return (
        <>
            {isPending ? (
                <p>Loading PayPal Button...</p> // Loader while the script is loading
            ) : (
                <PayPalButtons
                    style={buttonStyles}
                    createOrder={(data, actions) => {
                        return actions.order.create({
                            purchase_units: [
                                {
                                    amount: {
                                        value: price,
                                    },
                                },
                            ],
                        });
                    }}

                    onApprove={(data, actions) => {
                        return actions.order.capture().then(() => {
                            successOrder();
                        });
                    }}

                    onError={(err) => {
                        console.error("PayPal Buttons error:", err);
                    }}

                />
            )}
        </>
    );
}

export default function PaypalButton({ callback, price, currency }) {
    console.log(currency, price, callback)
    const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;
    const initialOptions = {
        clientId: paypalClientId,
        currency: currency === "usd" ? "USD" : "CAD",
        components: "buttons",
        "disable-funding": "paylater,card",
    };

    return (
        <PayPalScriptProvider options={initialOptions}>
            <PayPalButtonComponent callback={callback} price={price} />
        </PayPalScriptProvider>
    );
}
