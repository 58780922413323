import React, { useState } from 'react';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import CheckoutForm from "../SubscriptionCheckout";
import PayPalButton from '../SubscriptionPaypalButton';
import { stripePriIDs, paypalOriginPlanIDs, paypalDiscountPlanIDs } from '../data';


const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const baseUrl = process.env.REACT_APP_BASE_URL;
const stripePromise = loadStripe(stripeKey);





const SubscriptionModal = ({ isOpen, onClose, month, email, password, coupon, currency }) => {
    console.log(month, email, password, coupon, currency);

    const stripePriceId = stripePriIDs.find(option => option.month === Number(month))[currency];
    const paypalPriceId = paypalOriginPlanIDs.find(option => option.month === Number(month))[currency];
    const paypalDiscountPriceId = paypalDiscountPlanIDs.find(option => option.month === Number(month))[currency];
    const [paymentMethod, setPaymentMethod] = useState('credit');
    const [successMessage, setSuccessMessage] = useState(null);


    const handleAddCredit = (subscriptionType, subscriptionId) => {
        axios
            .post(`${baseUrl}/api/subscription/express-subscription`, { email, month, password, subscriptionType, subscriptionId })
            .then(() => {
                setSuccessMessage(`Your subscription payment processed successfully. Please check your email to verify your account and login.`);
                console.log("success");
            })
            .catch(() => {
                console.log("network error");
            });
    };




    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg p-6 w-full max-w-xl shadow-lg">
                <div className="flex justify-between items-center my-4">
                    <h2 className="text-lg font-bold text-blue-600 mb-4">Secure Payment</h2>
                    <button
                        onClick={onClose}
                        className="bg-red-500 p-2 hover:bg-red-600 text-white font-bold rounded transition"
                    >
                        Cancel
                    </button>
                </div>


                {successMessage ? (
                    <div className="mt-4 p-4 bg-green-100 text-green-800 rounded">
                        {successMessage}
                    </div>
                ) : <>

                    <div className="mb-4">
                        <label className="flex items-center space-x-2 mb-2">
                            <input
                                type="radio"
                                name="payment-method"
                                checked={paymentMethod === 'credit'}
                                onChange={() => setPaymentMethod('credit')}
                                className="form-radio text-blue-600"
                            />
                            <div className="flex w-full justify-between items-center gap-2">
                                <span className="text-2xl font-semibold">Credit card</span>
                                <img src="/image/card.png" alt="credit" className="h-8" />
                            </div>
                        </label>

                        {paymentMethod === 'credit' && (
                            <Elements stripe={stripePromise}>
                                <CheckoutForm
                                    priceId={stripePriceId}
                                    month={month}
                                    callBack={handleAddCredit}
                                    email={email}
                                    coupon={coupon}
                                />
                            </Elements>
                        )}
                    </div>

                    <div className="mb-6">
                        <label className="flex items-center space-x-2 mb-2">
                            <input
                                type="radio"
                                name="payment-method"
                                checked={paymentMethod === 'paypal'}
                                onChange={() => setPaymentMethod('paypal')}
                                className="form-radio text-blue-600"
                            />
                            <div className="flex w-full justify-between items-center gap-2">
                                <span className="text-2xl font-semibold">PayPal</span>
                                <img src="/image/paypal.png" alt="paypal" className="h-8" />
                            </div>
                        </label>
                        {paymentMethod === 'paypal' && (
                            <PayPalButton
                                callback={handleAddCredit}
                                planId={coupon ? paypalDiscountPriceId : paypalPriceId}
                            />
                        )}
                    </div>
                </>}

            </div>
        </div>
    );
};

export default SubscriptionModal;
